<template>
  <v-expansion-panel class="chat-info-members">
    <v-expansion-panel-header>
      {{ $t("common.options") }}
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <ChatAddUser
        v-if="
          conversation.type === 'group' && chatAuthParticipant.is_chat_admin
        "
        :conversation="conversation"
      />

      <v-btn
        v-if="conversation.created_by !== $user.id"
        color="transparent"
        block
        class="justify-start"
        :loading="$loading('chat/leaveConversation') === conversation.id"
        @click="leaveConversation"
      >
        <v-icon left>mdi-account-cancel</v-icon>
        {{ $t("chat.leave") }}
      </v-btn>

      <v-btn
        v-if="conversation.created_by === $user.id"
        color="transparent"
        block
        class="justify-start"
        :loading="$loading('chat/deleteConversation') === conversation.id"
        @click="deleteConversation"
      >
        <v-icon left>mdi-trash-can</v-icon>
        {{ $t("chat.delete") }}
      </v-btn>

      <v-btn
        color="transparent"
        block
        class="justify-start"
        :loading="$loading('chat/archiveConversation') === conversation.id"
        @click="archiveConversation"
      >
        <v-icon left>mdi-archive</v-icon>
        {{ $t("chat.archive") }}
      </v-btn>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";
import ChatAddUser from "./ChatAddUser.vue";

export default {
  mixins: [chatMixin],

  components: { ChatAddUser },

  methods: {
    async deleteConversation() {
      if (!(await this.$confirm())) {
        return;
      }

      this.$store
        .dispatch("chat/deleteConversation", this.conversation.id)
        .catch(() => {
          this.$toast.error("Error while deleting conversation.");
        });
    },

    async leaveConversation() {
      if (!(await this.$confirm())) {
        return;
      }

      this.$store
        .dispatch("chat/leaveConversation", this.conversation.id)
        .catch(() => {
          this.$toast.error("Error while leaving conversation.");
        });
    },

    archiveConversation() {
      this.$store
        .dispatch("chat/archiveConversation", this.conversation.id)
        .catch(() => {
          this.$toast.error("Error while archiving conversation.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-row:not(:last-of-type) {
  margin-bottom: 10px;
}

:deep {
  .v-btn {
    font-size: 14px;
    font-weight: normal;
  }
}
</style>

<template>
  <column-layout class="page" sticky-sides :noRight="!showChatInfo || !active">
    <!-- Left -->
    <template v-slot:left>
      <ChatList />
    </template>

    <!-- Main -->
    <template>
      <Chat v-if="active" @click:info="showChatInfo = true" />

      <ChatEmpty v-else-if="!$loading('chat/fetchConversations')" />
    </template>

    <!-- Right -->
    <template v-slot:right>
      <ChatInfo :conversation="active" @close="showChatInfo = false" />
    </template>
  </column-layout>
</template>

<script>
import Chat from "@/components/app/chat/Chat.vue";
import ChatEmpty from "@/components/app/chat/ChatEmpty.vue";
import ChatInfo from "@/components/app/chat/ChatInfo.vue";
import ChatList from "@/components/app/chat/ChatList.vue";

export default {
  metaInfo: { title: "Chat" },

  components: { Chat, ChatEmpty, ChatInfo, ChatList },

  data: () => ({
    showChatInfo: false,
  }),

  computed: {
    active() {
      return this.$store.getters["chat/active"];
    },
  },

  created() {
    if (!this.$store.getters["chat/conversations"].meta.current_page) {
      this.$store.dispatch("chat/fetchConversations");
    }
  },

  destroyed() {
    this.$store.commit("chat/SET_ACTIVE", null);
  },
};
</script>

<style lang="scss"></style>

<template>
  <v-menu
    v-model="menu"
    bottom
    left
    offset-y
    :close-on-content-click="false"
    content-class="menu-modal"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
    </template>

    <v-card class="d-flex flex-column ebony">
      <!-- Unsend -->
      <ChatUnsendMessageButton
        :message="message"
        color="secondary"
        small
        class="justify-start"
      />
    </v-card>
  </v-menu>
</template>

<script>
import ChatUnsendMessageButton from "./ChatUnsendMessageButton.vue";

export default {
  components: { ChatUnsendMessageButton },

  props: {
    message: Object,
  },

  data: () => ({
    menu: false,
  }),
};
</script>

<style></style>

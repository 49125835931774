<template>
  <v-btn v-bind="$attrs" :loading="loading" @click="toggleMute">
    <v-icon left>
      {{ participant.is_muted ? "mdi-account-voice" : "mdi-account-voice-off" }}
    </v-icon>

    {{ participant.is_muted ? $t("common.unmute") : $t("common.mute") }}
  </v-btn>
</template>

<script>
export default {
  props: {
    conversation: Object,
    participant: Object,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    user() {
      return this.participant.user;
    },
  },

  methods: {
    toggleMute() {
      this.loading = true;
      this.$store
        .dispatch("chat/toggleMuteUser", {
          id: this.conversation.id,
          participant: this.participant,
        })
        .then(() => {
          this.$set(this.participant, "is_muted", !this.participant.is_muted);
        })
        .catch(() => {
          this.$toast.error("Error while (un)muting user in chat.");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>

import store from "@/store";
import { placeholderImage, userImage } from "@/utils/utils";

const firstUser = (conversation) => conversation.participants[0].user;

export default {
  props: {
    conversation: Object,
  },

  computed: {
    chatAuthParticipant() {
      return this.conversation
        ? this.findChatParticipant(this.conversation, this.$user.id)
        : null;
    },

    chatImage() {
      return this.conversation ? this.getChatImage(this.conversation) : null;
    },

    chatName() {
      return this.conversation ? this.getChatName(this.conversation) : null;
    },
  },

  methods: {
    findChatParticipant(conversation, id) {
      return conversation.participants.find((p) => p.user.id === id);
    },

    getChatImage(conversation) {
      if (conversation.image) {
        return conversation.image.thumbnail_url || conversation.image.image_url;
      }

      if (conversation.type === "single") {
        return userImage(firstUser(conversation));
      }

      const userId = conversation.last_message?.user_id;

      return this.getChatUserImage(conversation, userId);
    },

    getChatName(conversation) {
      if (conversation.name) {
        return conversation.name;
      }

      if (conversation.participants.length < 2) {
        return firstUser(conversation).username;
      }

      return conversation.participants.map((p) => p.user.username).join(", ");
    },

    getChatUserImage(conversation, userId) {
      if (userId === store.getters["auth/user"].id) {
        return userImage(store.getters["auth/user"]);
      }

      const user = this.findChatParticipant(conversation, userId)?.user;

      if (user) {
        return userImage(user);
      }

      return placeholderImage(60, "%20");
    },
  },
};

<template>
  <v-menu
    v-model="menu"
    bottom
    left
    offset-y
    :close-on-content-click="false"
    content-class="menu-modal"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
    </template>

    <v-card class="d-flex flex-column ebony">
      <!-- Report modal -->
      <ReportModal
        v-if="user.id !== $user.id"
        field="user_id"
        category="user"
        :id="user.id"
        @close="menu = false"
      />

      <!-- Mute -->
      <ChatMuteUserButton
        v-if="
          chatAuthParticipant.is_chat_admin &&
          conversation.created_by !== user.id
        "
        :conversation="conversation"
        :participant="participant"
        color="secondary"
        small
        class="justify-start"
      />

      <!-- Set admin -->
      <ChatSetAdminButton
        v-if="
          chatAuthParticipant.is_chat_admin &&
          conversation.created_by !== user.id
        "
        :conversation="conversation"
        :participant="participant"
        color="secondary"
        small
        class="justify-start"
      />

      <!-- Remove from chat -->
      <ChatRemoveUserButton
        v-if="
          chatAuthParticipant.is_chat_admin &&
          conversation.created_by !== user.id
        "
        :conversation="conversation"
        :user="user"
        color="secondary"
        small
        class="justify-start"
      />
    </v-card>
  </v-menu>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";
import ChatMuteUserButton from "./ChatMuteUserButton.vue";
import ChatRemoveUserButton from "./ChatRemoveUserButton.vue";
import ChatSetAdminButton from "./ChatSetAdminButton.vue";
import ReportModal from "@/components/app/reports/ReportModal.vue";

export default {
  mixins: [chatMixin],

  components: {
    ChatMuteUserButton,
    ChatRemoveUserButton,
    ChatSetAdminButton,
    ReportModal,
  },

  props: {
    participant: Object,
  },

  data: () => ({
    menu: false,
  }),

  computed: {
    user() {
      return this.participant.user;
    },
  },
};
</script>

<style></style>

<template>
  <div
    v-if="conversation.type === 'single' || !chatAuthParticipant.is_muted"
    class="chat-input ma-4"
  >
    <Attachments
      v-if="formData.attachments.length"
      v-model="formData.attachments"
      editable
      class="mb-5"
      style="height: 100px; width: 100px"
      :imgAttrs="{
        'max-height': '100px',
      }"
    />

    <div class="d-flex">
      <!-- Textarea -->
      <v-textarea
        v-model="formData.message"
        class="input pt-0 mt-0"
        background-color="ocean-dark"
        ref="input"
        :placeholder="$t('chat.inputPlaceholder')"
        auto-grow
        rows="1"
        rounded
        hide-details="auto"
        maxlength="1000"
        :append-icon="
          canSubmit
            ? $loading('chat/sendMessage')
              ? 'mdi-loading mdi-spin'
              : 'mdi-send'
            : null
        "
        :disabled="$loading('chat/sendMessage')"
        @click:append="send"
        @keypress.enter.exact.prevent="send"
        @keypress.enter.shift.exact.prevent="insertNewline"
      />

      <div class="d-flex align-center ml-1">
        <!-- Emoji -->
        <EmojiMenu
          v-model="formData.message"
          iconOnly
          autoreplace
          @close="$refs.input.focus()"
        />

        <!-- Image input -->
        <file-input
          v-model="formData.attachments"
          type="image"
          append
          iconOnly
          :disabled="!!formData.attachments.length"
        />
      </div>
    </div>
  </div>

  <v-alert v-else text color="warning" class="ma-3 text-center">
    {{ $t("chat.mutedAlert") }}
  </v-alert>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";
import Attachments from "@/components/common/Attachments.vue";
import EmojiMenu from "@/components/common/EmojiMenu.vue";

export default {
  mixins: [chatMixin],

  components: { Attachments, EmojiMenu },

  props: {
    conversation: Object,
  },

  data() {
    return {
      formData: {
        attachments: [],
        message: "",
      },
    };
  },

  computed: {
    canSubmit() {
      return !!this.formData.message || this.formData.attachments.length;
    },
  },

  methods: {
    send() {
      if (!this.canSubmit) {
        return;
      }

      this.$store.dispatch("chat/sendMessage", this.getPayload()).then(() => {
        this.formData.attachments = [];
        this.$refs.input.reset();
        this.$refs.input.focus();
      });
    },

    insertNewline() {
      this.formData.message += "\n";
    },

    getPayload() {
      const payload = Object.assign({}, this.formData);
      payload.chat_conversation_id = this.conversation.id;

      if (!payload.message) {
        delete payload.message;
      }

      return payload;
    },
  },
};
</script>

<style lang="scss" scoped></style>

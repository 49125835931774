<template>
  <v-expansion-panel class="chat-info-media">
    <v-expansion-panel-header>
      {{ $t("chat.sharedMedia") }}
    </v-expansion-panel-header>

    <v-expansion-panel-content
      style="
        max-height: 55vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-right: -10px;
        padding-right: 10px;
      "
    >
      <spinner
        v-if="
          !meta.current_page && $loading('chat/fetchMedia') === conversation.id
        "
        center
        style="height: 100px"
      />

      <div v-else class="d-flex flex-wrap" style="gap: 10px">
        <v-img
          v-for="image in data"
          v-img="{
            src: image.attachment_url,
            group: conversation.id,
            thumbnails: true,
          }"
          :key="image.id"
          :src="image.attachment_url"
          width="100"
          max-width="100"
          height="100"
          class="v-img rounded"
        />
      </div>

      <div v-if="$utils.canLoadMore(meta)" class="text-center pa-3">
        <v-btn
          color="primary"
          small
          :loading="$loading('chat/fetchMedia') === conversation.id"
          @click="fetchData"
        >
          {{ $t("common.loadMore") }}
        </v-btn>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";

export default {
  mixins: [chatMixin],

  props: {
    isActive: Boolean,
  },

  data: () => ({
    data: [],
    meta: {
      current_page: 0,
    },
  }),

  watch: {
    isActive(val) {
      if (val) {
        this.resetData();
        this.fetchData();
      }
    },
  },

  methods: {
    fetchData() {
      this.$store
        .dispatch("chat/fetchMedia", {
          id: this.conversation.id,
          params: { page: this.meta.current_page + 1 },
        })
        .then(({ data, meta }) => {
          this.data.push(...data);
          this.meta = meta;
        });
    },

    resetData() {
      this.data = [];
      this.meta = { current_page: 0 };
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn {
    font-size: 14px;
    font-weight: normal;
  }
}
</style>

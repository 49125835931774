<template>
  <v-btn v-bind="$attrs" :loading="loading" @click="removeFromChat">
    <v-icon left>mdi-account-cancel</v-icon>
    {{ $t("common.remove") }}
  </v-btn>
</template>

<script>
export default {
  props: {
    conversation: Object,
    user: Object,
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async removeFromChat() {
      if (!(await this.$confirm())) {
        return;
      }

      this.loading = true;
      this.$store
        .dispatch("chat/removeUser", {
          id: this.conversation.id,
          payload: { participants: [this.user.id] },
        })
        .then((conversation) => {
          this.$set(
            this.conversation,
            "participants",
            conversation.participants
          );
        })
        .catch(() => {
          this.$toast.error("Error while removing user from chat.");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","close-on-content-click":false,"content-class":"menu-modal"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"d-flex flex-column ebony"},[(_vm.user.id !== _vm.$user.id)?_c('ReportModal',{attrs:{"field":"user_id","category":"user","id":_vm.user.id},on:{"close":function($event){_vm.menu = false}}}):_vm._e(),(
        _vm.chatAuthParticipant.is_chat_admin &&
        _vm.conversation.created_by !== _vm.user.id
      )?_c('ChatMuteUserButton',{staticClass:"justify-start",attrs:{"conversation":_vm.conversation,"participant":_vm.participant,"color":"secondary","small":""}}):_vm._e(),(
        _vm.chatAuthParticipant.is_chat_admin &&
        _vm.conversation.created_by !== _vm.user.id
      )?_c('ChatSetAdminButton',{staticClass:"justify-start",attrs:{"conversation":_vm.conversation,"participant":_vm.participant,"color":"secondary","small":""}}):_vm._e(),(
        _vm.chatAuthParticipant.is_chat_admin &&
        _vm.conversation.created_by !== _vm.user.id
      )?_c('ChatRemoveUserButton',{staticClass:"justify-start",attrs:{"conversation":_vm.conversation,"user":_vm.user,"color":"secondary","small":""}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
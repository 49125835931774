var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.conversation.type === 'single' || !_vm.chatAuthParticipant.is_muted)?_c('div',{staticClass:"chat-input ma-4"},[(_vm.formData.attachments.length)?_c('Attachments',{staticClass:"mb-5",staticStyle:{"height":"100px","width":"100px"},attrs:{"editable":"","imgAttrs":{
      'max-height': '100px',
    }},model:{value:(_vm.formData.attachments),callback:function ($$v) {_vm.$set(_vm.formData, "attachments", $$v)},expression:"formData.attachments"}}):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-textarea',{ref:"input",staticClass:"input pt-0 mt-0",attrs:{"background-color":"ocean-dark","placeholder":_vm.$t('chat.inputPlaceholder'),"auto-grow":"","rows":"1","rounded":"","hide-details":"auto","maxlength":"1000","append-icon":_vm.canSubmit
          ? _vm.$loading('chat/sendMessage')
            ? 'mdi-loading mdi-spin'
            : 'mdi-send'
          : null,"disabled":_vm.$loading('chat/sendMessage')},on:{"click:append":_vm.send,"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.send.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.insertNewline.apply(null, arguments)}]},model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", $$v)},expression:"formData.message"}}),_c('div',{staticClass:"d-flex align-center ml-1"},[_c('EmojiMenu',{attrs:{"iconOnly":"","autoreplace":""},on:{"close":function($event){return _vm.$refs.input.focus()}},model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", $$v)},expression:"formData.message"}}),_c('file-input',{attrs:{"type":"image","append":"","iconOnly":"","disabled":!!_vm.formData.attachments.length},model:{value:(_vm.formData.attachments),callback:function ($$v) {_vm.$set(_vm.formData, "attachments", $$v)},expression:"formData.attachments"}})],1)],1)],1):_c('v-alert',{staticClass:"ma-3 text-center",attrs:{"text":"","color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("chat.mutedAlert"))+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }
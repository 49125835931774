<template>
  <div
    v-if="conversation"
    :key="conversation.id"
    class="chat-info widget card pa d-flex flex-column pos-rel"
    style="max-height: calc(100vh - 150px)"
    :style="{ width: $vuetify.breakpoint.lgAndUp ? '300px' : 'unset' }"
  >
    <close-button
      @click="$emit('close')"
      class="pos-abs"
      style="top: 10px; right: 10px"
    />

    <!-- Avatar -->
    <ChatAvatar
      :conversation="conversation"
      :size="80"
      editable
      class="mx-auto"
    />

    <!-- Name -->
    <div class="d-flex justify-center align-center">
      <div
        class="name font-size-18 font-weight-bold text-center my-3"
        :title="chatName"
      >
        {{ chatName }}
      </div>

      <ChatNameInput :conversation="conversation" />
    </div>

    <v-expansion-panels accordion flat tile v-model="activePanel">
      <ChatInfoMembers
        v-if="conversation.type === 'group' || conversation.type === 'general'"
        :conversation="conversation"
      />

      <ChatInfoMedia
        :conversation="conversation"
        :isActive="activePanel === 1"
      />

      <ChatInfoOptions :conversation="conversation" />
    </v-expansion-panels>
  </div>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";
import ChatAvatar from "./ChatAvatar.vue";
import ChatInfoMembers from "./ChatInfoMembers.vue";
import ChatInfoMedia from "./ChatInfoMedia.vue";
import ChatInfoOptions from "./ChatInfoOptions.vue";
import ChatNameInput from "./ChatNameInput.vue";

export default {
  mixins: [chatMixin],

  components: {
    ChatAvatar,
    ChatInfoMembers,
    ChatInfoMedia,
    ChatInfoOptions,
    ChatNameInput,
  },

  data: () => ({
    activePanel: null,
  }),
};
</script>

<style lang="scss" scoped>
.name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep {
  .v-expansion-panels {
    button,
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
}
</style>

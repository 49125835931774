<template>
  <div
    class="chat-empty widget card pa h-100 d-flex align-center justify-center flex-column"
  >
    <ChatStartModal />

    <img
      :src="require('@/assets/images/quick-chat.svg')"
      class="mt-10"
      style="width: 100%; max-width: 500px"
      alt="Chat illustration"
    />
  </div>
</template>

<script>
import ChatStartModal from "./ChatStartModal.vue";

export default {
  components: { ChatStartModal },
};
</script>

<style></style>

<template>
  <div
    class="chat-message"
    :class="{ sent, received: !sent, 'is-recent': isRecent }"
  >
    <div class="d-flex">
      <!-- Avatar -->
      <v-avatar v-if="!sent" class="avatar align-self-end mr-2" :size="30">
        <v-img :src="image" :width="30" :height="30" :alt="chatName" />
      </v-avatar>

      <!-- Menu -->
      <div v-if="sent" class="menu align-self-center ml-auto mr-2">
        <ChatMessageMenu v-if="!message.unsent_at" :message="message" />
      </div>

      <!-- Content -->
      <div
        class="content pa-3 rounded-lg"
        style="max-width: 300px"
        :class="{ primary: sent, secondary: !sent }"
      >
        <Attachments
          v-if="!message.unsent_at && message.attachments.length"
          v-model="message.attachments"
          class="mb-2"
        />

        <p
          v-if="!message.unsent_at"
          class="text mb-0 text-formatted font-size-14"
          v-text="message.message"
        />

        <p
          v-else
          class="mb-0 font-size-14 font-italic"
          v-text="$t('chat.unsent')"
        />
      </div>
    </div>

    <!-- Time -->
    <div
      v-if="!isRecent"
      class="time text--secondary"
      :class="{ 'text-right': sent, 'ml-10': !sent }"
      :title="$utils.formatDatetime(message.created_at)"
    >
      <small>{{ $utils.dayjs(message.created_at).format("HH:mm") }}</small>
    </div>
  </div>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";
import Attachments from "@/components/common/Attachments.vue";
import ChatMessageMenu from "./ChatMessageMenu.vue";

export default {
  mixins: [chatMixin],

  components: { Attachments, ChatMessageMenu },

  props: {
    message: Object,
    isRecent: Boolean,
  },

  computed: {
    sent() {
      return this.message.user_id === this.$user.id;
    },

    image() {
      return this.getChatUserImage(this.conversation, this.message.user_id);
    },
  },

  created() {
    this.$eventBus.$on("chat:unsent", (message) => {
      if (message.id === this.message.id) {
        this.$set(this.message, "unsent_at", message.unsent_at);
        this.$set(this.message, "message", null);
      }
    });
  },

  destroyed() {
    this.$eventBus.$off("chat:unsent");
  },
};
</script>

<style lang="scss">
.sent .content {
  border-bottom-right-radius: 0 !important;
}

.received .content {
  border-bottom-left-radius: 0 !important;
}

.chat-message.is-recent {
  .v-avatar {
    visibility: hidden;
  }
}
</style>

<template>
  <v-dialog v-model="dialog" width="500" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :icon="icon"
          :color="icon ? 'white' : 'primary'"
          rounded
          :width="icon ? undefined : 200"
        >
          <v-icon :left="!icon">mdi-chat-plus</v-icon>
          <template v-if="!icon">{{ $t("chat.start") }}</template>
        </v-btn>
      </slot>
    </template>

    <v-form
      class="chat-start-modal"
      v-model="formValid"
      @submit.prevent="submit"
      ref="form"
    >
      <v-card>
        <!-- Header -->
        <v-card-title>
          <h3>{{ $t("chat.start") }}</h3>
          <v-spacer />
          <close-button @click="close" />
        </v-card-title>

        <v-divider />

        <!-- Main -->
        <v-card-text>
          <UserSearchField
            v-model="formData.participants"
            :label="$t('chat.to')"
            filled
            multiple
            chips
            deletable-chips
            :rules="[$rules.required, $rules.selection.min(1)]"
          />

          <v-text-field
            v-if="formData.participants.length > 1"
            v-model="formData.name"
            :label="$t('chat.groupName')"
            filled
          />

          <api-response :response="formResponse" class="mt-4 mb-0" />
        </v-card-text>

        <!-- Footer -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            type="submit"
            rounded
            min-width="130"
            :disabled="!formValid"
            :loading="$loading('chat/start')"
          >
            {{ $t("common.start") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import UserSearchField from "@/components/app/users/UserSearchField.vue";

export default {
  components: { UserSearchField },

  props: {
    icon: Boolean,
  },

  data: () => ({
    dialog: false,
    formData: {
      participants: [],
      name: "",
    },
    formValid: false,
    formResponse: null,
  }),

  methods: {
    submit() {
      this.$store
        .dispatch("chat/start", this.getPayload())
        .then((conversation) => {
          this.$store.commit("chat/SET_ACTIVE", conversation);
          this.close();
        })
        .catch(() => {
          this.$toast.error("Error while starting chat.");
        });
    },

    getPayload() {
      const payload = Object.assign({}, this.formData);

      if (!payload.name || payload.participants.length < 2) {
        delete payload.name;
      }

      return payload;
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.dialog = false;
      this.reset();
    },
  },
};
</script>

<template>
  <div class="chat-avatar">
    <avatar-input
      v-if="editable && chatAuthParticipant.is_chat_admin"
      :value="chatImage"
      size="80"
      :loading="$loading('chat/updateConversation') === conversation.id"
      @input="updateImage"
    />

    <v-avatar
      v-else
      class="chat-avatar"
      :class="{ 'cursor-pointer': canClick }"
      :size="size"
      @click="onClick"
    >
      <v-img :src="chatImage" :width="size" :height="size" :alt="chatName" />
    </v-avatar>
  </div>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";

export default {
  mixins: [chatMixin],

  props: {
    size: {
      type: [Number, String],
      default: 50,
    },
    clickable: Boolean,
    editable: Boolean,
  },

  computed: {
    canClick() {
      return this.clickable && this.conversation.participants.length === 1;
    },
  },

  methods: {
    onClick() {
      if (!this.canClick) {
        return;
      }

      this.$router.push({
        name: "app.user",
        params: { id: this.conversation.participants[0].user.username },
      });
    },

    updateImage(image) {
      this.$store
        .dispatch("chat/updateConversation", {
          id: this.conversation.id,
          payload: { image },
        })
        .then((conversation) => {
          this.$set(this.conversation, "image", conversation.image);
        })
        .catch(() => {
          this.$toast.error("Error while updating chat group image.");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

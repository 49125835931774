<template>
  <v-expansion-panel class="chat-info-members">
    <v-expansion-panel-header>
      {{ $t("groups.members") }}
    </v-expansion-panel-header>

    <v-expansion-panel-content
      style="
        max-height: 55vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-right: -10px;
        padding-right: 10px;
      "
    >
      <UserRow
        v-for="participant in conversation.participants"
        :key="participant.user.id"
        :user="participant.user"
        :avatarSize="40"
        fontSize="14px"
      >
        <template v-slot:actions>
          <div class="d-flex" style="gap: 7px">
            <v-btn
              v-if="participant.is_muted"
              x-small
              color="error"
              fab
              title="Muted"
            >
              <v-icon size="16">mdi-account-voice-off</v-icon>
            </v-btn>

            <v-btn
              v-if="participant.is_chat_admin"
              x-small
              color="primary"
              fab
              title="Admin"
            >
              <v-icon size="30">mdi-alpha-a</v-icon>
            </v-btn>

            <ChatMemberMenu
              v-if="$user.id !== participant.user.id"
              :conversation="conversation"
              :participant="participant"
            />
          </div>
        </template>
      </UserRow>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ChatMemberMenu from "./ChatMemberMenu.vue";
import UserRow from "@/components/app/users/UserRow.vue";

export default {
  components: { ChatMemberMenu, UserRow },

  props: {
    conversation: Object,
  },
};
</script>

<style lang="scss" scoped>
.user-row:not(:last-of-type) {
  margin-bottom: 15px;
}
</style>

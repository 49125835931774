<template>
  <v-btn v-bind="$attrs" :loading="loading" @click="onClick">
    <v-icon left>mdi-alpha-a-circle</v-icon>

    {{
      participant.is_chat_admin ? $t("chat.unsetAdmin") : $t("chat.setAdmin")
    }}
  </v-btn>
</template>

<script>
export default {
  props: {
    conversation: Object,
    participant: Object,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    user() {
      return this.participant.user;
    },
  },

  methods: {
    onClick() {
      this.loading = true;
      this.$store
        .dispatch("chat/toggleAdmin", {
          id: this.conversation.id,
          participant: this.participant,
        })
        .then(() => {
          this.$set(
            this.participant,
            "is_chat_admin",
            !this.participant.is_chat_admin
          );
        })
        .catch(() => {
          this.$toast.error("Error while (un)setting chat admin.");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>

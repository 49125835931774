var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{staticClass:"chat-info-media"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t("chat.sharedMedia"))+" ")]),_c('v-expansion-panel-content',{staticStyle:{"max-height":"55vh","overflow-y":"auto","overflow-x":"hidden","margin-right":"-10px","padding-right":"10px"}},[(
        !_vm.meta.current_page && _vm.$loading('chat/fetchMedia') === _vm.conversation.id
      )?_c('spinner',{staticStyle:{"height":"100px"},attrs:{"center":""}}):_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"10px"}},_vm._l((_vm.data),function(image){return _c('v-img',{directives:[{name:"img",rawName:"v-img",value:({
          src: image.attachment_url,
          group: _vm.conversation.id,
          thumbnails: true,
        }),expression:"{\n          src: image.attachment_url,\n          group: conversation.id,\n          thumbnails: true,\n        }"}],key:image.id,staticClass:"v-img rounded",attrs:{"src":image.attachment_url,"width":"100","max-width":"100","height":"100"}})}),1),(_vm.$utils.canLoadMore(_vm.meta))?_c('div',{staticClass:"text-center pa-3"},[_c('v-btn',{attrs:{"color":"primary","small":"","loading":_vm.$loading('chat/fetchMedia') === _vm.conversation.id},on:{"click":_vm.fetchData}},[_vm._v(" "+_vm._s(_vm.$t("common.loadMore"))+" ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
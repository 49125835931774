<template>
  <div class="chat-header pa-4 d-flex align-center">
    <!-- Avatar -->
    <ChatAvatar
      :conversation="conversation"
      clickable
      class="mr-3"
      :size="60"
    />

    <!-- Name -->
    <div class="name font-size-18 font-weight-bold">{{ chatName }}</div>

    <v-spacer />

    <v-icon @click="$emit('click:info')">mdi-information-outline</v-icon>
  </div>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";
import ChatAvatar from "./ChatAvatar.vue";

export default {
  mixins: [chatMixin],

  components: { ChatAvatar },
};
</script>

<style lang="scss" scoped>
.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <div
    v-if="conversation"
    class="chat widget card d-flex flex-column"
    style="height: calc(100vh - 150px)"
  >
    <!-- Header -->
    <ChatHeader
      :conversation="conversation"
      @click:info="$emit('click:info')"
    />

    <v-divider />

    <!-- Loader -->
    <spinner
      v-if="!messages.meta.current_page && $loading('chat/fetchMessages')"
      medium
      center
    />

    <!-- Chat box -->
    <div v-else class="chat-box-wrapper h-100">
      <div class="chat-box pa-4 pb-0">
        <!-- Load more -->
        <div
          v-if="$utils.canLoadMore(messages.meta)"
          class="load-more text-center mb-3"
        >
          <v-btn
            color="primary"
            :loading="$loading('chat/fetchMessages')"
            small
            rounded
            @click="fetchMessages"
          >
            {{ $t("common.loadMore") }}
          </v-btn>
        </div>

        <template v-for="(message, i) in messages.data">
          <!-- Date -->
          <div
            v-if="!sameDateCheck(i, i - 1, 'day')"
            :key="'chat-date-' + message.id"
            class="chat-date text-center text--secondary mb-2"
          >
            <small>{{ $utils.formatDate(message.created_at) }}</small>
          </div>

          <!-- Message -->
          <ChatMessage
            :key="message.id"
            :conversation="conversation"
            :message="message"
            :isRecent="
              sameUserCheck(i, i + 1) && sameDateCheck(i, i + 1, 'minute')
            "
          />
        </template>
      </div>
    </div>

    <!-- Input -->
    <ChatInput :key="conversation.id" :conversation="conversation" />
  </div>
</template>

<script>
import ChatHeader from "./ChatHeader.vue";
import ChatInput from "./ChatInput.vue";
import ChatMessage from "./ChatMessage.vue";

export default {
  components: { ChatHeader, ChatInput, ChatMessage },

  data: () => ({
    messages: {
      data: [],
      meta: {
        current_page: 0,
      },
    },
  }),

  computed: {
    conversation() {
      return this.$store.getters["chat/active"];
    },
  },

  created() {
    this.$eventBus.$on("chat:received", this.onMessageReceived);
    this.fetchMessages();
  },

  destroyed() {
    this.$eventBus.$off("chat:received");
  },

  watch: {
    conversation(val) {
      this.reset();
      val && this.fetchMessages();
    },
  },

  methods: {
    fetchMessages() {
      if (!this.conversation) {
        return;
      }

      const params = {
        "exact_search[chat_conversation_id]": this.conversation.id,
        page: this.messages.meta.current_page + 1,
        "sort[id]": "DESC",
      };

      return this.$store
        .dispatch("chat/fetchMessages", params)
        .then((res) => {
          this.messages.meta = res.meta;
          this.messages.data.unshift(...res.data);

          if (this.messages.meta.current_page === 1) {
            this.conversation.unread_messages = false;
          }
        })
        .catch(() => {
          this.$toast.error("Error while fetching messages.");
        });
    },

    onMessageReceived({ message }) {
      if (!this.conversation) {
        return;
      }

      if (message.chat_conversation_id === this.conversation.id) {
        this.messages.data.push(message);
      }
    },

    reset() {
      this.messages = {
        data: [],
        meta: {
          current_page: 0,
        },
      };
    },

    sameUserCheck(i1, i2) {
      return (
        this.messages.data[i1]?.user_id === this.messages.data[i2]?.user_id
      );
    },

    sameDateCheck(i1, i2, unit) {
      const date1 = this.messages.data[i1]?.created_at;
      const date2 = this.messages.data[i2]?.created_at;

      if (!date1 || !date2) {
        return false;
      }

      return this.$utils.dayjs(date1).isSame(date2, unit);
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-box-wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

.chat-message {
  &.is-recent {
    margin-bottom: 5px;
  }

  &:not(.is-recent):not(:last-of-type) {
    margin-bottom: 10px;
  }
}
</style>

<template>
  <v-dialog v-model="dialog" width="500" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="transparent"
        block
        class="justify-start"
        :loading="$loading('chat/deleteConversation') === conversation.id"
      >
        <v-icon left>mdi-account-plus</v-icon>
        {{ $t("chat.addUser") }}
      </v-btn>
    </template>

    <v-form
      class="chat-add-user"
      v-model="formValid"
      @submit.prevent="submit"
      ref="form"
    >
      <v-card>
        <!-- Header -->
        <v-card-title>
          <h3>{{ $t("chat.addUser") }}</h3>
          <v-spacer />
          <close-button @click="close" />
        </v-card-title>

        <v-divider />

        <!-- Main -->
        <v-card-text>
          <UserSearchField
            v-if="dialog"
            v-model="formData.userId"
            filled
            :rules="[$rules.required]"
          />

          <api-response :response="formResponse" class="mt-4 mb-0" />
        </v-card-text>

        <!-- Footer -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            type="submit"
            rounded
            min-width="130"
            :disabled="!formValid"
            :loading="$loading('chat/addUser') === conversation.id"
          >
            {{ $t("common.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import UserSearchField from "@/components/app/users/UserSearchField.vue";

export default {
  components: { UserSearchField },

  props: {
    conversation: Object,
  },

  data: () => ({
    dialog: false,
    formData: {
      userId: null,
    },
    formValid: false,
    formResponse: null,
    loading: false,
  }),

  methods: {
    submit() {
      this.loading = true;
      this.$store
        .dispatch("chat/addUser", {
          id: this.conversation.id,
          payload: { participants: [this.formData.userId] },
        })
        .then((conversation) => {
          this.$set(
            this.conversation,
            "participants",
            conversation.participants
          );
          this.close();
        })
        .catch(() => {
          this.$toast.error("Error while adding user to chat.");
        })
        .finally(() => (this.loading = false));
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.dialog = false;
      this.reset();
    },
  },
};
</script>

<template>
  <v-menu
    v-if="conversation.type === 'group' && chatAuthParticipant.is_chat_admin"
    class="chat-name-input"
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-pencil-circle</v-icon>
      </v-btn>
    </template>

    <v-form
      v-model="formValid"
      @submit.prevent="updateName"
      class="d-flex align-center secondary pa-3"
    >
      <v-text-field
        v-model="formData.name"
        :rules="[$rules.required]"
        autofocus
        solo
        hide-details
        maxlength="30"
      />

      <v-btn
        color="primary"
        type="submit"
        class="ml-3"
        :disabled="!formValid"
        :loading="$loading('chat/updateConversation') === conversation.id"
        fab
        small
      >
        <v-icon>mdi-check-bold</v-icon>
      </v-btn>
    </v-form>
  </v-menu>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";

export default {
  mixins: [chatMixin],

  data: () => ({
    menu: false,
    formValid: false,
    formData: {
      name: "",
    },
  }),

  watch: {
    menu(val) {
      if (val && this.conversation.name) {
        this.formData.name = this.conversation.name;
      }
    },
  },

  methods: {
    updateName() {
      this.$store
        .dispatch("chat/updateConversation", {
          id: this.conversation.id,
          payload: { name: this.formData.name },
        })
        .then((conversation) => {
          this.$set(this.conversation, "name", conversation.name);
          this.menu = false;
        })
        .catch(() => {
          this.$toast.error("Error while updating chat group name.");
        });
    },
  },
};
</script>

<style></style>

<template>
  <v-btn v-bind="$attrs" :loading="loading" @click="onClick">
    <v-icon left>mdi-cancel</v-icon>

    {{ $t("chat.unsend") }}
  </v-btn>
</template>

<script>
export default {
  props: {
    message: Object,
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    onClick() {
      this.loading = true;
      this.$store
        .dispatch("chat/unsendMessage", this.message.id)
        .then((message) => {
          this.$set(this.message, "unsent_at", message.unsent_at);
          this.$set(this.message, "message", null);
        })
        .catch(() => {
          this.$toast.error("Error while unsending message.");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>

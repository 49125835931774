<template>
  <div v-if="items.length" class="attachments">
    <!-- Video -->
    <Plyr v-if="isVideo" :src="items[0].attachment_url" class="pos-rel w-100">
      <template v-slot:append>
        <v-btn
          v-if="editable"
          color="primary"
          fab
          small
          style="position: absolute; top: 7px; right: 7px"
          @click.stop.prevent="deleteItem(0)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </Plyr>

    <!-- Images -->
    <v-row v-else>
      <v-col
        v-for="(item, index) in items"
        :key="index"
        :cols="cols"
        class="d-flex child-flex"
      >
        <a
          :href="item.attachment_url"
          :data-gallery="key"
          class="glightbox pos-rel"
        >
          <!-- Images -->
          <v-img
            v-if="item.type === 'image' || item.type === 'gif'"
            :src="item.attachment_url"
            alt="Attachment"
            class="rounded-lg cursor-pointer"
            @error="onError(item)"
            v-bind="imgAttrs"
          />

          <!-- Delete button -->
          <v-btn
            v-if="editable"
            color="primary"
            fab
            small
            style="position: absolute; top: 7px; right: 7px"
            @click.stop.prevent="deleteItem(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Plyr from "@/components/common/Plyr.vue";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

export default {
  components: { Plyr },

  props: {
    value: Array,
    post: Object,
    editable: Boolean,
    imgAttrs: Object,
  },

  data: () => ({
    glightbox: null,
    key: "",
  }),

  computed: {
    attachments: {
      get() {
        return this.value || this.post.attachments;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    items() {
      return this.attachments && this.attachments.length
        ? this.attachments.map((i) => this.mapItem(i))
        : [];
    },

    isVideo() {
      return this.items[0].type === "video";
    },

    itemCount() {
      return this.items.length;
    },

    cols() {
      switch (this.itemCount) {
        case 1:
          return 12;

        case 2:
          return 6;

        case 3:
          return 4;

        default:
          return 6;
      }
    },
  },

  created() {
    this.key = new Date().getTime();
  },

  mounted() {
    this.initGlightbox();
  },

  methods: {
    initGlightbox() {
      this.glightbox = GLightbox();
    },

    mapItem(i) {
      if (i instanceof File) {
        i = {
          type: i.type.split("/")[0],
          attachment_url: URL.createObjectURL(i),
        };
      }

      return i;
    },

    async deleteItem(index) {
      const attachment = this.attachments[index];

      // Check if item is local file file
      if (!attachment.id) {
        this.attachments.splice(index, 1);
        return;
      }

      this.$store
        .dispatch("posts/deleteAttachment", {
          postId: this.post.id,
          attachmentId: attachment.id,
        })
        .then(() => {
          this.attachments.splice(index, 1);

          if (this.post) {
            this.post.attachments.splice(index, 1);
          }
        })
        .catch(() => {
          this.$toast.error("Error while deleting attachment.");
        });
    },

    onError(img) {
      const altSrc = `https://placehold.jp/400x200/?text=Error`;
      this.$set(img, "attachment_url", altSrc);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="chat-list widget" style="width: 280px">
    <div class="card">
      <div class="d-flex align-center pa-4 pr-1">
        <!-- Search -->
        <SearchInput
          v-model="$store.state.chat.search"
          @search="onSearch"
          class="rounded-lg mr-1"
        />

        <!-- Archives -->
        <v-btn icon @click="toggleArchives">
          <v-icon>
            {{ showArchives ? "mdi-archive-off" : "mdi-archive" }}
          </v-icon>
        </v-btn>

        <!-- Chat start -->
        <ChatStartModal icon />
      </div>

      <!-- Archived chats label -->
      <small
        v-if="showArchives"
        class="d-block px-4 mb-2 text--secondary text-center"
      >
        {{ $t("chat.archived") }}
      </small>

      <!-- Spinner -->
      <spinner v-if="isLoading" center style="height: 100px" />

      <!-- List -->
      <div
        v-else
        class="chat-list-items"
        style="max-height: calc(100vh - 150px - 76px)"
      >
        <template v-for="(item, i) in conversations.data">
          <!-- Item -->
          <ChatListItem
            :key="item.id"
            :conversation="item"
            highlightActive
            class="pa-4"
          />

          <!-- Divider -->
          <div
            v-if="i < conversations.data.length - 1"
            :key="'divider-' + item.id"
            class="divider px-4"
          >
            <v-divider />
          </div>
        </template>
      </div>

      <!-- Empty -->
      <v-alert
        v-if="!isLoading && !conversations.data.length"
        text
        class="ma-4 mt-0"
      >
        {{
          $t(
            $store.state.chat.search
              ? "common.noDataFound"
              : showArchives
              ? "chat.noArchivedChats"
              : "chat.noChats"
          )
        }}
      </v-alert>

      <!-- Load more -->
      <div
        v-if="$utils.canLoadMore(conversations.meta)"
        class="load-more text-center pb-4"
      >
        <v-btn
          color="primary"
          :loading="$loading(fetchActionName)"
          small
          rounded
          @click="$store.dispatch(fetchActionName)"
        >
          {{ $t("common.loadMore") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ChatListItem from "./ChatListItem.vue";
import ChatStartModal from "./ChatStartModal.vue";
import SearchInput from "@/components/common/SearchInput.vue";

export default {
  components: { ChatListItem, ChatStartModal, SearchInput },

  data: () => ({
    showArchives: false,
  }),

  computed: {
    conversations() {
      return this.showArchives
        ? this.$store.getters["chat/archives"]
        : this.$store.getters["chat/conversations"];
    },

    active() {
      return this.$store.getters["chat/active"];
    },

    isLoading() {
      return (
        !this.conversations.meta.current_page &&
        this.$loading(this.fetchActionName)
      );
    },

    fetchActionName() {
      return this.showArchives
        ? "chat/fetchArchives"
        : "chat/fetchConversations";
    },
  },

  mounted() {
    this.setDefaultConversation();
  },

  destroyed() {
    this.$store.state.chat.search = "";
    this.showArchives = false;
    this.onSearch();
  },

  methods: {
    onSearch() {
      this.$store.commit(
        this.showArchives ? "chat/RESET_ARCHIVES" : "chat/RESET_CONVERSATIONS"
      );
      this.$store.dispatch(this.fetchActionName);
    },

    setDefaultConversation() {
      if (!this.active && this.conversations.data[0]) {
        this.$store.commit("chat/SET_ACTIVE", this.conversations.data[0]);
      }
    },

    toggleArchives() {
      if (this.showArchives) {
        this.$store.commit("chat/RESET_ARCHIVES");
        this.$store.dispatch("chat/fetchConversations");
        this.showArchives = false;
      } else {
        this.$store.commit("chat/RESET_CONVERSATIONS");
        this.$store.dispatch("chat/fetchArchives");
        this.showArchives = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-list-items {
  &:hover {
    overflow-y: auto;
  }

  &:not(:hover) {
    overflow: hidden;
  }
}
</style>

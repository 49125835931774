<template>
  <div
    class="chat-list-item cursor-pointer"
    :class="{
      active: highlightActive && active && conversation.id === active.id,
      dense,
    }"
    @click="onClick"
  >
    <!-- Header -->
    <div class="header d-flex align-center">
      <!-- Avatar -->
      <ChatAvatar :conversation="conversation" :size="40" class="mr-3" />

      <div class="font-size-14 overflow-hidden">
        <!-- Username -->
        <div class="name font-weight-medium text-overflow-ellipsis max-1">
          {{ chatName }}
        </div>

        <!-- Date -->
        <datetime
          v-if="lastMessage"
          :value="lastMessage.created_at"
          class="date text--secondary font-size-12"
          fromNow
        />
      </div>

      <v-spacer />

      <!-- Unread messages notification -->
      <v-icon v-if="conversation.unread_messages" color="primary" x-small>
        mdi-circle
      </v-icon>
    </div>

    <!-- Last message -->
    <p v-if="lastMessage" class="last-message mb-0 mt-2 font-size-14">
      <span v-if="$user.id === lastMessage.user_id" class="text--secondary">
        {{ $t("chat.you") }}:
      </span>

      <span class="font-italic" v-if="lastMessage.unsent_at">
        {{ $t("chat.unsent") }}
      </span>
      <template v-else-if="lastMessage.message">
        {{ lastMessage.message }}
      </template>
      <i v-else>
        {{ $t("common.attachment") }}
      </i>
    </p>
  </div>
</template>

<script>
import chatMixin from "@/mixins/chat.mixin";
import ChatAvatar from "./ChatAvatar.vue";

export default {
  mixins: [chatMixin],

  components: { ChatAvatar },

  props: {
    dense: Boolean,
    highlightActive: Boolean,
  },

  computed: {
    user() {
      return this.conversation.participants[0];
    },

    lastMessage() {
      return this.conversation.last_message;
    },

    active() {
      return this.$store.getters["chat/active"];
    },
  },

  created() {
    this.$eventBus.$on("chat:unsent", (message) => {
      if (message.id === this.lastMessage.id) {
        this.$set(this.lastMessage, "unsent_at", message.unsent_at);
        this.$set(this.lastMessage, "message", null);
      }
    });
  },

  destroyed() {
    this.$eventBus.$off("chat:unsent");
  },

  methods: {
    onClick() {
      this.$store.commit("chat/SET_ACTIVE", this.conversation);
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-list-item {
  &:hover {
    background-color: $ocean-dark;
  }

  &.active {
    background-color: $ocean-dark;
  }
}

.last-message {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dense .last-message {
  -webkit-line-clamp: 1;
}
</style>
